<template>
  <div>
    <button class="btn btn-primary" @click="toggler = !toggler">Ver Fachada</button>

    <FsLightbox
      :toggler="toggler"
      :sources='this.imagenes'
      type="image"
    />
  </div>
</template>

<script>
import FsLightbox from "fslightbox-vue";

export default {
  components: { FsLightbox },
  props: {
    imagenes: {
      type: Array,
      default: () => [
      ],
    }
  },
  data() {
    return {
      toggler: false
    };
  }
};
</script>